export const BLACK = "#000000";
export const BLUE = "#0089EC";
export const BLUE_GREY = "#D3DAE1";
export const DARK_BLUE = "#24446A";
export const DARK_GREY = "#7C8FA6";
export const DARK_TEXT = "#283443";
export const DEEP_DARK_GREY = "#CECECE";
export const GREEN = "#03B595";
export const GREY = "#F5F5F5";
export const LIGHT_BLUE = "#F0F8FE";
export const LIGHT_GREY = "#F9F9F9";
export const LIGHT_WHITE_BLUE = "#FAFDFF";
export const MIDDLE_DARK_GREY = "#D4D4D4";
export const MIDDLE_GREY = "#E0E0E0";
export const MIDDLE_LIGHT_GREY = "#EDEDED";
export const ORANGE = "#F78749";
export const RED = "#F53D4D";
export const WHITE = "#FFFFFF";
export const YELLOW = "#FFFF00";
